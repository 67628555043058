import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "../../constants"
import Image from "../image"
import { useViewport } from "../../contexts/viewContext"
import PropTypes from "prop-types"

const background = css`
  position: absolute;
  z-index: -10;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Background = ({ storylineContent }) => {
  const { width } = useViewport()
  let imgPath = ""
  width > breakpoint
    ? (imgPath = storylineContent.background.relativePath)
    : (imgPath = storylineContent.backgroundMobile.relativePath)

  return (
    <div css={background}>
      <Image filename={imgPath} />
    </div>
  )
}

Background.propTypes = {
  storylineContent: PropTypes.object.isRequired
}

export default Background
