import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "../../constants"
import Image from "../image"
import { Link } from "gatsby"
import { useViewport } from "./../../contexts/viewContext"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const root = css`
  margin-top: 1.4rem;
  position: relative;
  display: flex;
  width: 100vw;
  height: auto;
  padding-bottom: 0.2rem;
  justify-content: space-around;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  padding-bottom: .6rem;
  }
`

const nav = css`
  width: 2.4rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (max-width: ${breakpoint}px) {
    margin: 1rem 0;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`
const textWrapper = css`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 0.6em;
    text-transform: uppercase;
    margin: 0 0.1rem;
  }
  @media (max-width: ${breakpoint}px) {
    margin-top: 0.2rem;
    p {
      font-size: 2em;
      z-index: 10;
      margin: 0 0.2rem;
    }
  }
`

const navRight = css`
  justify-content: flex-end;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column-reverse;
  }
`

const iconWrapper = css`
  width: 0.3rem;
  margin: 0 0.1rem;
  @media (max-width: ${breakpoint}px) {
    width: 20vw;
    z-index: 10;
  }
`

const background = css`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  z-index: -5;
`

const arrowIcon = css`
  width: 0.08rem;
  @media (max-width: ${breakpoint}px) {
    width: 0.3rem;
  }
`

const StorylineBottom = ({ storylineContent }) => {
  const data = useStaticQuery(graphql`
    query {
      allDataJson ( filter: {slug: {regex: "/storylines/"}}) {
        edges{
          node {
            slug
            title
            iconPath {
              relativePath
            }
            url
          }
        }
      }
    }
  `)

  let leftNode, rightNode, leftSlug, rightSlug
  switch (storylineContent.slug) {
    case "storylines_castle":
      leftSlug = "storylines_class"
      rightSlug = "storylines_gender"
      break
    case "storylines_gender":
      leftSlug = "storylines_castle"
      rightSlug = "storylines_class"
      break
    case "storylines_class":
      leftSlug = "storylines_gender"
      rightSlug = "storylines_castle"
      break
    default:
      break
  }

  data.allDataJson.edges.forEach(node => {
    if (node.node.slug===leftSlug) {
      leftNode = node.node
    }
    if (node.node.slug===rightSlug) {
      rightNode = node.node
    }
  })

  const { width } = useViewport()
  
  let imgPath = ""
  width > breakpoint
    ? (imgPath = storylineContent.footerBackground.relativePath)
    : (imgPath = storylineContent.footerBackgroundMobile.relativePath)

  return (
    <div css={root}>
      <div css={background}>
        <Image filename={imgPath} />
      </div>
      <Link css={nav} to={leftNode.url}>
        <div css={iconWrapper}>
          <Image filename={leftNode.iconPath.relativePath} />
        </div>
        <div css={textWrapper}>
          <div css={arrowIcon}><Image filename="assets/left-arrow.png" /></div>
          <p>{leftNode.title}</p>
        </div>
      </Link>
      <Link
        css={css`
          ${nav};
          ${navRight}
        `}
        to={rightNode.url}
      >
        <div css={textWrapper}>
          <p>{rightNode.title}</p>
          <div css={arrowIcon}><Image filename="assets/right-arrow.png" /></div>
        </div>
        <div css={iconWrapper}>
          <Image filename={rightNode.iconPath.relativePath} />
        </div>
      </Link>
    </div>
  )
}

StorylineBottom.propTypes = {
  storylineContent: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
}


export default StorylineBottom
