import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"
import PropTypes from "prop-types"
import AudioIcon from "../audioIcon"

const root = css`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  p {
    padding: 0;
    margin: 0.1rem 2rem;
    font-size: 0.6em;
    text-align: center;
  }
  @media (max-width: ${breakpoint}px) {
    padding: 0 0.3rem;
    box-sizing: border-box;
    margin-top: 2rem;
    p {
      box-sizing: border-box;
      font-size: 1.9em;
      margin: 0;
      width: 100%;
      margin-top: 1rem;
      padding: 0 0.4rem;
    }
  }
`

const background = css`
  width: 100vw;
  position: absolute;
  top: -0.8rem;
  left: 0;
  overflow: visible;
  z-index: -5;
`

const imgWrapper = css`
  margin-top: 1rem;
  width: 10vw;
  @media (max-width: ${breakpoint}px) {
    width: 50vw;
  }
`

const startTour = css`
  margin-top: 0.2rem;
  width: 1rem;
  cursor: pointer;
  outline: none;
  border: 2px solid #000;
  text-decoration: none;
  color: #000;
  font-size: 0.4em;
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  padding: 0.06rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoint}px) {
    width: 4rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1.8em;
    padding: 0.3rem;
  }
`

const arrow = css`
  width: 10%;
  margin-left: 2%;
`

const icon = css`
  position: absolute;
  width: 0.7rem;
  top: -0.2rem;
  right: 0.6rem;
  @media (max-width: ${breakpoint}px) {
    position: static;
    width: 40vw;
    margin-top: 7.3rem;
  }
`


const StorylineMid = ({ storylineContent }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { eq: "mp3" } }) {
          edges {
            ...AudioURL
          }
        }
      }
    `
  )
  const node = allFile.edges.find(n => {
    return n.node.name === storylineContent.audioClip
  })

  let url = ""
  if (node) {
    url = node.node.publicURL
  } else {
    console.log(`${storylineContent.audioClip} can not been found!`)
  }

  const { width } = useViewport()
  let imgPath = ""
  width > breakpoint
    ? (imgPath = storylineContent.paperBlockImg.relativePath)
    : (imgPath = storylineContent.paperBlockImgMobile.relativePath)

  return (
    <div css={root}>
      <div css={background}>
        <Image filename={imgPath} />
      </div>
      <div css={icon}>
        <AudioIcon url={url} audioFace={storylineContent.audioFace} />
      </div>
      <div css={imgWrapper}>
        <Image filename={storylineContent.castleIcon.relativePath} />
      </div>
      <p>{storylineContent.intro}</p>
      <Link
        css={startTour}
        to="/tour" 
      >
        start tour
        <div css={arrow}>
          <Image filename="assets/black-arrow.png" />
        </div>
      </Link>
    </div>
  )
}

StorylineMid.propTypes = {
  storylineContent: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
}

export default StorylineMid
