/***
 * All the audio icon is render by this component
 * need an url to load the audio
 * using the asset in assets/listen.png
 * Notice: the wrapper to use this component should only set width
 *          don't set height
 *          because the asset is not a square but a rectangle
 *          set height will make the progress circle wrong 
 */

import React from "react"
import { css } from "@emotion/core"
import Image from "./image"
import { breakpoint } from "./../constants"

const audioIcon = css`
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: none;
  position: relative;
`

const text = css`
  position: absolute;
  bottom: -0.12rem;
  font-family: "LoraItalic", serif;
  font-size: 0.1rem;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.4rem;
    bottom: -0.5rem;
  }
`

const progressContainer = css`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`

const progress = css`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 4px solid #FF8C00;
  border-radius: 50%;
  z-index: 10;
`

const circle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border: 4px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
`
const leftCircle = css`
  clip-path: inset(0 50% 0 0);
  z-index: 15;
`
const rightCircle = css`
  clip-path: inset(0 0 0 50%);
  z-index: 20;
`


const CircleProgress = ({ percent }) => {
  const [leftRotate, setLeftRotate] = React.useState(0)
  const [rightRotate, setRightRotate] = React.useState(0)
  const [rightColor, setRightColor] = React.useState("#fff")
  React.useEffect(() => {
    if (percent <= 50) {
      setRightRotate(percent * 3.6)
      setRightColor("#fff")
      setLeftRotate(0)
    } else {
      setRightRotate(0)
      setLeftRotate((percent - 50) * 3.6)
      setRightColor("#FF8C00")
    }
  }, [percent])
  return (
    <div css={progressContainer}>
      <div css={progress}></div>
      <div
        css={css`
          ${circle};
          ${leftCircle};
          transform: rotate(${leftRotate}deg);
        `}
      ></div>
      <div
        css={css`
          ${circle};
          ${rightCircle};
          transform: rotate(${rightRotate}deg);
          border-color: ${rightColor};
        `}
      ></div>
    </div>
  )
}

const AudioIcon = ({ url, audioFace }) => {
  const [audio, setAudio] = React.useState(null)
  const [playing, setPlaying] = React.useState(false)
  const [percent, setPercent] = React.useState(0)

  const toggle = () => {
    let newStatus = !playing;
    if (audio) {
      newStatus ? audio.play() : audio.pause()
    }
    setPlaying(newStatus)
  }
  const audioFaceImgPath = `assets/audio-characters/${audioFace}.png`

  React.useEffect(() => {
    setAudio(new Audio(url))
  }, [url])

  const handlePlaying = React.useCallback(() => {
    setPercent((audio.currentTime / audio.duration) * 100)
  }, [audio])

  const handleEnded = React.useCallback(() => {
    setPlaying(false)
    setPercent(0)
  }, [])
  React.useEffect(() => {
    if (audio) {
      /* playing ? audio.play() : audio.pause() */
      audio.addEventListener("timeupdate", handlePlaying)
      audio.addEventListener("ended", handleEnded)
    }
    return () => {
      if (audio) {
        audio.pause()
        audio.removeEventListener("timeupdate", handlePlaying)
        audio.removeEventListener("ended", handleEnded)
      }
    }
  }, [audio, handlePlaying, handleEnded])

  return (
    <div
      css={audioIcon}
      role="button"
      tabIndex={0}
      onKeyDown={toggle}
      onClick={toggle}
    >
      {
        playing
        ? <Image filename={audioFaceImgPath} />
        : <Image filename="assets/listen.png" />
      }
      <CircleProgress percent={percent} />
      <div css={text}>listen</div>
    </div>
  )
}

export default AudioIcon
