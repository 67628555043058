import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StorylineHeader from "../components/storyline/storylineHeader"
import StorylineMid from "../components/storyline/storylineMid"
import StorylineBottom from "../components/storyline/storylineBottom"
import Background from "../components/storyline/background"
import { graphql, useStaticQuery } from "gatsby"

const classPage = css`
  display: flex;
  font-size: 0.2rem;
  flex-direction: column;
  position: relative;
`
const ClassPage = () => {
    const data = useStaticQuery(graphql`
    query {
      dataJson(slug: {eq: "storylines_class"}) {
        ...StorylineContent
      }
    }
  `)
  const storylineContent = data.dataJson
  return (
    <Layout title="class">
      <SEO title="THE CLASSES" />
      <div css={classPage}>
        <Background storylineContent={storylineContent}  />
        <StorylineHeader storylineContent={storylineContent} />
        <StorylineMid storylineContent={storylineContent} />
        <StorylineBottom storylineContent={storylineContent} />
      </div>
    </Layout>
  )
}

export default ClassPage
