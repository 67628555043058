import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import PropTypes from "prop-types"

const root = css`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    margin: 0;
    padding: 0;
    font-size: 0.5em;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
  }
  h1 {
    margin: 0;
    padding: 0;
    margin-top: 0.3rem;
    font-size: 1em;
    color: white;
    text-transform: uppercase;
  }
  p {
    padding: 0;
    margin: 0.1rem 2rem;
    font-size: 0.58em;
    text-align: center;
  }
  @media (max-width: ${breakpoint}px) {
    padding: 0 0.7rem;
    box-sizing: border-box;
    h6 {
      font-size: 2em;
    }
    h1 {
      margin: 0.8rem 0;
      font-size: 4.4em;
      text-align: center;
    }
    p {
      margin: 0;
      font-size: 1.8em;
    }
  }
`
const imgWrapper = css`
  margin-top: 0.5rem;
  width: 14vw;
  margin-bottom: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    margin-top: 2rem;
    width: 70vw;
    margin-bottom: 0.4rem;
  }
`

// the width of castle is different with class
const castleImgWrapper = css`
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  width: 6vw;
  @media (max-width: ${breakpoint}px) {
    margin-top: 2rem;
    width: 24vw;
    margin-bottom: 0.4rem;
  }
`

const StorylineHeader = ({ storylineContent }) => (
  <div css={root}>
    <div css={css`${storylineContent.slug === "storylines_castle" ? castleImgWrapper : imgWrapper}`}>
      <Image filename={storylineContent.headerImg.relativePath} />
    </div>
    <h6>{storylineContent.subTitle}</h6>
    <h1>{storylineContent.characterName}</h1>
    <p>{storylineContent.characterIntro}</p>
  </div>
)

StorylineHeader.propTypes = {
  storylineContent: PropTypes.object.isRequired,
}


export default StorylineHeader
